.p-visual {
    position: relative;
    height: 400px;

    @include view-at(tab) {
        height: 300px;
    }
    @include view-at(sp) {
        height: 250px;
    }

    &::after {
        content: "";
        position: fixed;
        top: 80px;
        left: 0px;
        width: 100%;
        height: 400px;
        background-image: url(../images/common/bg-image-large.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;

        @include view-at(tab) {
            top: 56px;
            height: 300px;
        }
        @include view-at(sp) {
            height: 250px;
        }
    }

    &.business {
        &::after {
            background-image: url(../images/business/business.jpg);
        }
    }
    &.company {
        &::after {
            background-image: url(../images/company/company.jpg);
        }
    }
    &.contact {
        &::after {
            background-image: url(../images/contact/contact.jpg);
        }
    }
    &.recruit {
        &::after {
            background-image: url(../images/recruit/recruit.jpg);
        }
    }
    &.works {
        &::after {
            background-image: url(../images/works/works.jpg);
        }
    }

    &-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: $color-black-light;
    }

    &-title {
        font-size: rem(30px);
        font-weight: $medium;
        text-align: center;
        padding: 50px 100px 70px;
        background-color: rgba($color-white, .7);

        @include view-at(tab) {
            font-size: rem(24px);
            padding: 30px 60px 45px;
        }
        @include view-at(sp) {
            font-size: rem(18px);
            padding: 10px 40px 20px;
        }

        .icon {
            font-size: 125%;
            line-height: 2;
        }
        .small {
            font-size: 50%;
            font-weight: $regular;

            @include view-at(sp) {
                font-size: 60%;
            }
        }
    }
}