/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    padding: 0;
    z-index: index($z, menu);

    &-wrapper {
        position: relative;
        display: block;
        width: 44px;
        height: 40px;
        margin-left: 10px;
        margin: 0 auto;

        @include view-at(tab) {
            width: 34px;
            height: 30px;
        }
    }

    &-trigger,
    &-trigger span {
        display: block;
        transition: all .2s;
        box-sizing: border-box;
    }

    &-trigger {
        position: relative;
        width: 44px;
        height: 40px;

        @include view-at(tab) {
            width: 34px;
            height: 30px;
        }

        &-label {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            width: 100%;
            height: auto;
            color: $color-text;
            font-size: 14px;
            text-align: center;
            letter-spacing: 0.1em;
            margin: auto;
            background-color: transparent;

            @include view-at(tab) {
                font-size: 11px;
            }
        }
    }

    &-trigger span {
        position: absolute;
        left: 0;
        width: 44px;
        height: 2px;
        background-color: $color-text;

        @include view-at(tab) {
            width: 34px;
        }
    }

    &-trigger span:nth-of-type(1) {
        top: 0;
    }

    &-trigger span:nth-of-type(2) {
        top: 10px;

        @include view-at(tab) {
            top: 8px;
        }
    }

    &-trigger span:nth-of-type(3) {
        top: 20px;

        @include view-at(tab) {
            top: 16px;
        }
    }

    &-trigger.active span:nth-of-type(1) {
        -webkit-transform: translateY(10px) translateX(-1px) rotate(-45deg);
        transform: translateY(10px) translateX(-1px) rotate(-45deg);

        @include view-at(tab) {
            -webkit-transform: translateY(8px) translateX(-1px) rotate(-45deg);
            transform: translateY(8px) translateX(-1px) rotate(-45deg);
        }
    }

    &-trigger.active span:nth-of-type(2) {
        opacity: 0;
    }

    &-trigger.active span:nth-of-type(3) {
        -webkit-transform: translateY(-10px) translateX(-1px) rotate(45deg);
        transform: translateY(-10px) translateX(-1px) rotate(45deg);

        @include view-at(tab) {
            -webkit-transform: translateY(-8px) translateX(-1px) rotate(45deg);
            transform: translateY(-8px) translateX(-1px) rotate(45deg);
        }
    }
}