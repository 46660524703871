/* --------------------------
見出し
-------------------------- */
.c-h1 {
    font-size: rem(60px);
    font-weight: $medium;
    line-height: 1.2;
    letter-spacing: 0.1em;

    @include view-at(sp) {
        font-size: rem(30px);
    }
}

.c-h2 {
    font-size: rem(40px);
    font-weight: $medium;
    line-height: 1.4;
    letter-spacing: 0.15em;
    margin-bottom: 40px;
    
    @include view-at(tab) {
        font-size: rem(32px);
    }
    @include view-at(sp) {
        font-size: rem(24px);
        margin-bottom: 30px;
    }
    
    &--line {
        line-height: 1;
        padding-bottom: 0.4em;
        border-bottom: 2px solid $color-text;

        .en {
            color: $color-blue;
            font-size: rem(16px);
            line-height: 1;
            letter-spacing: 0.15em;

            @include view-at(sp) {
                font-size: rem(12px);
            }
        }
        .ja {
            font-size: rem(32px);
            line-height: 1.4;

            @include view-at(sp) {
                font-size: rem(24px);
            }
        }
    }
}

.c-h3 {
    font-size: rem(26px);
    font-weight: $medium;
    line-height: 1.6;
    letter-spacing: 0.15em;
    margin-bottom: 30px;
    padding-bottom: 0.25em;
    border-bottom: 1px solid $color-text;

    @include view-at(sp) {
        font-size: rem(18px);
        margin-bottom: 20px;
    }
}

.c-h4 {
    font-size: rem(22px);
    font-weight: $medium;
    line-height: 1.6;
    letter-spacing: 0.15em;
    margin-bottom: 20px;

    @include view-at(sp) {
        font-size: rem(16px);
        margin-bottom: 15px;
    }
}

.c-h5 {
    font-size: rem(18px);
    font-weight: $medium;
    line-height: 1.6;
    letter-spacing: 0.15em;
    margin-bottom: 20px;

    @include view-at(sp) {
        font-size: rem(14px);
        margin-bottom: 15px;
    }
}

.c-h6 {
    font-size: rem(14px);
    font-weight: $medium;
    line-height: 1.6;
    letter-spacing: 0.15em;
    margin-bottom: 20px;

    @include view-at(sp) {
        font-size: rem(12px);
        margin-bottom: 15px;
    }
}

.c-h--center-line {
    @include center-line($color: $color-text, $padding: 10px, $height: 1px);
    margin-bottom: 5px;
}