/* --------------------------
スクロールに合わせてフェードイン
-------------------------- */
.c-fadein {
    opacity: 0;
    transition: all 0.75s;

    &.js-active {
        opacity: 1;
    }

    &-wrapper {
        overflow: hidden;
    }

    &--top {
        transform: translateY(-20%);

        &.js-active {
            transform: translateY(0);
        }
    }

    &--bottom {
        transform: translateY(100px);

        &.js-active {
            transform: translateY(0);
        }
    }

    &--left {
        transform: translateX(-100px);

        &.js-active {
            transform: translateY(0);
        }
    }

    &--right {
        transform: translateX(100px);

        &.js-active {
            transform: translateY(0);
        }
    }

    &--delay {
        @include view-at(pc) {
            &-01 { transition-delay: .05s; }
            &-02 { transition-delay: .1s; }
            &-03 { transition-delay: 0.15s; }
            &-04 { transition-delay: 0.2s; }
            &-05 { transition-delay: 0.25s; }
            &-06 { transition-delay: 0.3s; }
            &-07 { transition-delay: 0.35s; }
            &-08 { transition-delay: 0.4s; }
            &-09 { transition-delay: 0.45s; }
            &-10 { transition-delay: 0.5s; }
        }
    }

    &--timing {
        transition-timing-function: cubic-bezier(0.77, 0, 0.18, 1);
    }
}