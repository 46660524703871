/* --------------------------
ボタン
-------------------------- */
.c-button {
    position: relative;
    display: inline-block;
    width: auto;
    min-width: 350px;
    color: $color-white;
    font-family: inherit;
    font-size: rem(16px);
    line-height: 1.5;
    letter-spacing: 0.15em;
    text-align: center;
    padding: 1em 0.5em;
    background-color: $color-navy;
    border: 2px solid $color-navy;
    border-radius: 45px;
    cursor: pointer;
    transition: all .3s;

    @include view-at(sp) {
        min-width: 285px;
        font-size: rem(14px);
        letter-spacing: 0.1em;
    }

    &:hover {
        color: $color-navy;
        background-color: $color-white;
        opacity: 1;

        .arrow {
            &::before {
                border-color: $color-navy;
            }
        }
    }

    .arrow {
        position: relative;
        padding-right: 1em;

        &::before {
            content: '';
            position: absolute;
            top: 0.5em;
            right: 0;
            display: inline-block;
            width: 0.5em;
            height: 0.5em;
            border-top: 2px solid $color-white;
            border-right: 2px solid $color-white;
            transform: rotate(45deg);
            transition: all .3s;
        }
    }

    &--small {
        min-width: 255px;
        // height: 40px;
        font-size: rem(14px);
        border-radius: 20px;
        padding: 0.5em;
    }
}