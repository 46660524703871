.wp-pagenavi {
    text-align: center;
    margin-top: 60px;

    .pages {
        display: none;
    }

    span,
    a {
        /*数字部分の共通CSS　大きさなど*/
        display: inline-block;
        margin: 0 5px;
        padding: 7px 10px;
        color: $color-text;
        text-decoration: none;
        border: 1px solid $color-text;
        border-radius: 2px;

        &.current,
        &:hover {
            /*現在のページ*/
            color: $color-white;
            background-color: $color-text;
            border: 1px solid $color-text;
        }

        &.nextpostslink,
        &.previouspostslink {
            border: 1px solid $color-white;

            &:hover {
                color: $color-white;
                background-color: $color-text;
                border-color: $color-text;
            }
        }
    }

    .page {
        color: $color-text;
        background-color: $color-white;
        border: 1px solid $color-white;

        &:hover {
            color: $color-white;
            background-color: $color-text;
            border-color: $color-text;
        }
    }
}