/* --------------------------
小塚ゴシック
-------------------------- */
@mixin kozuka {
    font-family: 'kozuka-gothic-pr6n', sans-serif;
    font-weight: 500;
    font-style: normal;
}

/* --------------------------
icoMoon
-------------------------- */
@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?mt3rh4');
    src: url('../fonts/icomoon.eot?mt3rh4#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?mt3rh4') format('truetype'),
        url('../fonts/icomoon.woff?mt3rh4') format('woff'),
        url('../fonts/icomoon.svg?mt3rh4#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-contact:before {
    content: "\e900";
}

.icon-company:before {
    content: "\e901";
}

.icon-recruit:before {
    content: "\e902";
}

.icon-works:before {
    content: "\e903";
}

.icon-business:before {
    content: "\e904";
}

.icon-top:before {
    content: "\e905";
}

.icon-virtual:before {
    content: "\e906";
}