@charset "UTF-8";

.recruit {
    &-subnav {
        a {
            min-width: auto;
            width: 100%;
            border-radius: 0;

            &.current {
                color: $color-navy;
                background-color: $color-blue-snow;
                pointer-events: none;
            }
        }

        &:nth-child(2) {
            a {
                @include view-at(pc) {
                    border-left: none;
                    border-right: none;
                }
            }
        }
        &:nth-child(3) {
            a {
                @include view-at(sp) {
                    border-top: none;
                }
            }
        }
    }

    &-voice {
        &:not(:first-of-type) {
            margin-top: 100px;

            @include view-at(sp) {
                margin-top: 60px;
            }
        }

        &-mv {
            position: relative;
            margin-bottom: 40px;

            @include view-at(sp) {
                margin-bottom: 30px;
            }
        }

        &-inner {
            @include view-at(pc) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 2% 5%;
            }
            @include view-at(sp) {
                margin-top: 10px;
            }
        }

        &-copy {
            color: $color-white;
            font-size: rem(24px);
            letter-spacing: 0.1em;

            @include view-at(tab) {
                font-size: rem(20px);
            }
            @include view-at(sp) {
                font-size: rem(16px);
                margin-bottom: 5px;
            }

            span {
                padding-left: 0.35em;
                background-color: $color-navy;
            }
        }

        &-industry {
            &.white {
                @include view-at(pc) {
                    color: $color-white;
                }
            }
        }

        &-name {
            font-size: rem(32px);
            font-weight: $medium;
            margin-bottom: 0;

            @include view-at(tab) {
                font-size: rem(28px);
            }
            @include view-at(sp) {
                font-size: rem(20px);
                line-height: 1.6;
            }

            &.white {
                @include view-at(pc) {
                    color: $color-white;
                }
            }

            .en {
                font-size: 50%;
                letter-spacing: 0.1em;
            }
        }

        &-detail {
            &.white {
                @include view-at(pc) {
                    color: $color-white;
                }
            }
        }
    }

    &-job {
        &:not(:first-of-type) {
            margin-top: 60px;

            @include view-at(sp) {
                margin-top: 40px;
            }
        }
    }
}