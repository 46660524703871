/* --------------------------
線
-------------------------- */

.c-line {
    display: inline-block;
    width: 100vw;
    height: 10px;
    margin: 0 calc(50% - 50vw);
    background-color: $color-black;

    @include view-at(sp) {
        height: 8px;
    }

    &-wrapper {
        position: relative;
        height: 10px;

        @include view-at(sp) {
            height: 8px;
        }
    }

    &-half {
        position: absolute;
        top: 0;
        width: 50vw;
        margin: 0;

        @include view-at(sp) {
            width: 100vw;
            left: -20px;
        }

        @include view-at(pc) {
            &.left {
                right: 0;
            }

            &.right {
                left: 0;
            }
        }
    }
}