
/* --------------------------
color
-------------------------- */

$color-text: #333;
$color-text-light: #777;

$color-navy: #10316B;
$color-blue: #0B409C;
$color-blue-snow: #F2F7FF;

$color-red: #8e0d14;
$color-yellow: #FFE867;

$color-white: #fff;
$color-white-dark: rgba(255, 255, 255, .85);
$color-white-light: rgba(255, 255, 255, .5);
$color-white-snow: rgba(255, 255, 255, 0.15);

$color-black: #000;
$color-black-dark: rgba(0, 0, 0, .85);
$color-black-light: rgba(0, 0, 0, .5);
$color-black-snow: rgba(0, 0, 0, .15);

$color-gray: #666;
$color-gray-dark: #999;
$color-gray-light: #CCC;
$color-gray-snow: #EEE;

$color-bg: #E1E1E1;
$color-border: #777;
$color-shadow: rgba(#10316B, .2);
$color-button: linear-gradient(0deg, $color-navy 0%, $color-blue 100%);

/* --------------------------
spacing
-------------------------- */
$space: 100px, 70px, 40px, 30px;
$space-tab: 80px, 60px, 40px, 30px;
$space-sp: 60px, 40px, 30px, 20px;

/* --------------------------
font
-------------------------- */
$font-size: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$font-size-sp: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height-sp: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$black: 900;
$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;
$thin: 100;

/* --------------------------
contents width  
-------------------------- */
$contents-width: 1500px;
$contents-middle-width: 1340px;
$contents-narrow-width: 1170px;
$contents-padding: 30px;
$contents-padding-sp: 20px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  menu,
  header,
  footer,
  modal
);
