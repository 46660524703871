/* --------------------------
リスト
-------------------------- */

.c-list {
    >li {
        position: relative;
        line-height: 1.5;
        margin-bottom: 0.5em;

        @include view-at(sp) {
            font-size: rem(12px);
        }
    }

    &--point {
        >li {
            position: relative;
            padding-left: 1em;

            &::before {
                content: '';
                position: absolute;
                top: 0.75em;
                left: 0.25em;
                display: inline-block;
                width: 4px;
                height: 4px;
                background-color: $color-text;
                border-radius: 2px;
            }
        }
    }

    &--link {
        >li {
            position: relative;
            padding-left: 15px;

            &::before {
                content: '';
                position: absolute;
                top: 6.5px;
                left: 0;
                display: inline-block;
                width: 8px;
                height: 8px;
                border-top: 1px solid $color-blue;
                border-right: 1px solid $color-blue;
                transform: rotate(45deg);
            }
        }
    }

    &--horizontal {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include view-at(sp) {
            display: block;
        }

        li {
            margin-left: 20px;
            margin-bottom: 0;

            @include view-at(sp) {
                margin-left: 0;
                margin-bottom: 10px;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &--border {
        li {
            width: 100%;
            line-height: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 14px;
            background-position: left 16px;
            border-bottom: 1px dashed $color-blue;
            overflow-x: hidden;

            &:last-child {
                border-bottom: 0;
            }

            a {
                color: $color-black;
                text-decoration: none;

                &:hover {
                    color: $color-blue;
                }
            }
        }
    }

    &--order {
        padding-left: 15px;
    }

    &--note {
        li {
            position: relative;
            color: $color-gray-dark;
            font-size: rem(11px);
            line-height: rem(18px);
            margin-bottom: 0;
            padding-left: 1.2em;

            &::before {
                content: '※';
                position: absolute;
                top: 0px;
                left: 0;
                display: inline-block;
            }
        }
    }
}