.p-cta {
    color: $color-white;
    text-align: center;

    p {
        text-align: center;
        line-height: 1.8;
    }

    &-bg {
        padding: 35px 0;
        background-color: $color-bg;

        @include view-at(sp) {
            padding: 30px 0;
        }
    }

    &-lead {
        font-size: rem(14px);
        margin-bottom: 5px;
    }

    &-tel {
        color: $color-white;
        font-size: rem(42px);
    }

    &-detail {
        font-size: rem(12px);
        margin-top: 5px;
    }
}