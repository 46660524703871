/* --------------------------
テキスト装飾
-------------------------- */

.u-text-accent {
    color: $color-blue;
}

.u-text-important {
    color: $color-red;
}

.u-text-inverse {
    color: $color-white;
}

.u-text-sub {
    color: $color-text-light;
}

.u-text-note {
    font-size: rem(11px);
    line-height: 1.6;
}

.u-text-bold {
    font-weight: $bold;
}

.u-text-medium {
    font-weight: $medium;
}

.u-text-regular {
    font-weight: $regular;
}

.u-text-light {
    font-weight: $light;
}

.u-text-lh {
    &-30 {
        line-height: 3;

        &--sp {
            @include view-at(sp) {
                line-height: 3;
            }
        }
    }
    &-25 {
        line-height: 2.5;

        &--sp {
            @include view-at(sp) {
                line-height: 2.5;
            }
        }
    }
    &-20 {
        line-height: 2;

        &--sp {
            @include view-at(sp) {
                line-height: 2;
            }
        }
    }
    &-15 {
        line-height: 1.5;

        &--sp {
            @include view-at(sp) {
                line-height: 1.5;
            }
        }
    }
    &-10 {
        line-height: 1;

        &--sp {
            @include view-at(sp) {
                line-height: 1;
            }
        }
    }
}

.u-text-lts {
    &-25 {
        letter-spacing: 0.25em;
    }
    &-20 {
        letter-spacing: 0.2em;
    }
    &-15 {
        letter-spacing: 0.15em;
    }
    &-10 {
        letter-spacing: 0.1em;
    }
    &-05 {
        letter-spacing: 0.05em;
    }

    &-00 {
        letter-spacing: 0;
    }
}


.u-text-highlight {
    background: linear-gradient(transparent 10%, $color-yellow 10%, $color-yellow 85%, transparent 85%, transparent 100%);
}

.u-text-x-large {
    font-size: rem(40px);

    @include view-at(sp) {
        font-size: rem(24px);
    }
}

.u-text-large {
    font-size: rem(30px);

    @include view-at(sp) {
        font-size: rem(20px);
    }
}

.u-text-bit-large {
    font-size: rem(24px);

    @include view-at(sp) {
        font-size: rem(18px);
    }
}

.u-text-medium {
    font-size: rem(18px);

    @include view-at(sp) {
        font-size: rem(16px);
    }
}

.u-text-default {
    font-size: rem(16px);

    @include view-at(sp) {
        font-size: rem(14px);
    }
}

.u-text-small {
    font-size: rem(14px);

    @include view-at(sp) {
        font-size: rem(12px);
    }
}

.u-text-x-small {
    font-size: rem(12px);

    @include view-at(sp) {
        font-size: rem(10px);
    }
}