html {
    font-size: 16px;
}

body {
    font-family: 'Noto Sans JP', '游ゴシック体', YuGothic, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', 'Meiryo', sans-serif;
    font-weight: 400;
    color: $color-text;
    font-size: rem(16px);
    overflow-x: hidden;
}

a {
    color: $color-text;
    text-decoration: none;
    transition: all .3s;

    &:hover {
        opacity: 0.7;
    }
}

p {
    margin-bottom: 1em;
    font-weight: $regular;
    line-height: 2;
    letter-spacing: 0.15em;

    @include view-at(sp) {
        font-size: rem(12px);
        line-height: 1.8;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

img {
    width: auto;
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
}

.main {
    padding-top: 80px;

    @include view-at(tab) {
        padding-top: 56px;
    }
}