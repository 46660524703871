/* --------------------------
テーブル
-------------------------- */

.c-table {
    width: 100%;
    font-size: rem(16px);
    line-height: 1.5;
    letter-spacing: 0.1em;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    word-break: break-all;

    @include view-at(sp) {
        font-size: rem(14px);
    }

    thead {
        tr {
            th {
                padding: 15px;
            }
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid $color-border;
        }

        td {
            width: 70%;
            padding: 10px 20px;
            vertical-align: top;
            border: none !important;

            @include view-at(sp) {
                width: 100%;
                display: block;
            }

            .small {
                font-size: 80%;
            }
            
            &:first-child {
                width: 30%;
                padding: 10px 20px;
                word-break: keep-all;
                font-weight: $regular;
                vertical-align: top;
                background-color: $color-gray-snow;

                @include view-at(sp) {
                    width: 100%;
                    display: block;
                    border-bottom: 1px solid $color-border !important;
                }

                .small {
                    font-size: 80%;
                }
            }
        }
    }

    &--border {
        border-collapse: collapse;

        @include view-at(sp) {
            border-top: 1px solid $color-gray;
        }

        thead {
            tr {
                border-bottom: 1px solid $color-gray;

                th {
                    font-weight: $bold;
                    padding: 20px 30px 20px 0;
                }
            }
        }

        tbody {

            th,
            td {
                border: 1px solid $color-gray;
            }

            th {
                @include view-at(sp) {
                    border-top: none;
                    border-bottom: none;
                }
            }
        }
    }
}