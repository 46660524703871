/* --------------------------
背景色
-------------------------- */

.u-bg-color {
    background-color: $color-bg;
}

.u-bg-black {
    background-color: $color-black;
}

.u-bg-gray {
    background-color: $color-gray;
}

.u-bg-gray-light {
    background-color: $color-gray-light;
}

.u-bg-white {
    background-color: $color-white;
}

.u-bg-blue-snow {
    background-color: $color-blue-snow;
}