/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {

    &-list {
        list-style: none;
        margin: 0;
        display: flex;
        margin-left: 20px;
        padding-bottom: 14px;

        @include view-at(sp) {
            flex-wrap: wrap;
        }

        li {
            color: $color-text;
            font-size: rem(12px);

            @include view-at(sp) {
                line-height: 1.4;
            }

            &::after {
                content: '/';
                padding-right: 0.2em;
            }

            &:last-child {
                &::after {
                    content: '';
                }
            }

            &:first-child {
                a {
                    color: $color-navy;
                }
            }

            a {
                color: $color-text;
            }
        }
    }
}