/* --------------------------
枠線
-------------------------- */

.u-border {
    border: 3px solid $color-navy;
    padding: 10px;

    &-bottom {
        border-bottom: 3px solid $color-navy;
        padding-bottom: 10px;
    }

    &-top {
        border-top: 3px solid $color-navy;
        padding-top: 10px;
    }

    &-right {
        border-right: 3px solid $color-navy;
        padding-right: 10px;
    }

    &-left {
        border-left: 3px solid $color-navy;
        padding-left: 10px;
    }

    &-thin {
        border-width: 1px;
    }

    &-bold {
        border-width: 5px;
    }

    &--white {
        border-color: $color-white;
    }

    &--gray-light {
        border-color: $color-gray-light;
    }

    &--gray {
        border-color: $color-gray;
    }

    &--black {
        border-color: $color-black;
    }
}