.p-cv {
    padding-bottom: 100px;

    @include view-at(sp) {
        width: 80%;
        margin: auto;
        padding-bottom: 60px;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include view-at(sp) {
            display: block;
        }
    }

    &-item {
        &:not(:last-child) {
            margin-right: 1px;

            @include view-at(sp) {
                margin-right: 0;
                margin-bottom: 1px;
            }
        }
    }

    &-button {
        &--request {
            background: $color-gray;
        }

        &--reserve {
            background: $color-gray;
        }

        &--red {
            background: $color-gray;
        }
    }

    &-banner {
        width: 100%;
        text-align: center;
        margin-top: 40px;

        @include view-at(sp) {
            margin-top: 20px;
        }

        img {
            @include view-at(pc) {
                max-width: 500px;
            }
        }
    }
}