/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
    @include view-at(tab) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(39, 39, 39, 0);
        transition: all .3s;
        visibility: hidden;
        z-index: 1000;

        &.visible {
            background-color: rgba(39, 39, 39, 0.7);
            visibility: visible;

            .p-gnav-inner {
                transform: translateX(0);
            }
        }
    }

    &-inner {
        @include view-at(tab) {
            width: 100%;
            height: 100%;
            margin: 0 0 0 auto;
            padding: 76px 30px 40px;
            background-color: $color-white;
            transform: translateX(100%);
            transition: all .3s;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;

        &-item {
            position: relative;

            @include view-at(tab) {
                width: calc(100% / 3);
                padding: 10px;
            }
            @include view-at(sp) {
                width: 100%;
            }

            &:not(:first-child) {
                @include view-at(overtab) {
                    margin-left: 30px;
                }
            }

            a {
                display: flex;
                color: $color-black;
                font-size: rem(14px);
                line-height: 1;
                
                @include view-at(tab) {
                    font-size: rem(16px);
                    padding: 5px 0;
                }

                &:hover {
                    color: $color-navy;
                    opacity: 1;
                }

                .icon {
                    font-size: rem(16px);
                    margin-right: 5px;

                    @include view-at(tab) {
                        font-size: rem(20px);
                    }
                }
            }

            &.current {
                a {
                    color: $color-navy;
                }
            }
        }
    }
}