/* --------------------------
エクストラナビ
-------------------------- */

.p-exnav {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include view-at(tab) {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60px;
        background-color: $color-white;
    }

    &-item {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        li {
            margin-left: 40px;

            @include view-at(tab) {
                margin-left: 0;
            }
        }
    }

    &-text {
        @include view-at(tab) {
            display: none;
        }

        li {
            position: relative;

            a {
                color: $color-text;
                font-size: rem(14px);
                line-height: 1;
                letter-spacing: 0.075em;
            }
        }
    }

    &-button {
        @include view-at(tab) {
            display: none;
        }
    }

    &-fixed {
        display: none;

        @include view-at(tab) {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 60px;

            li {
                width: calc(100% / 4);
                height: 100%;

                &:not(:last-child) {
                    border-right: 1px solid $color-white;
                }
            }

            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: $color-white;
                font-size: rem(9px);
                align-items: center;
                height: 100%;
                padding: 5px;
                background-color: $color-button;

                img {
                    max-width: 22px;
                    padding-bottom: 6px;
                }
            }
        }
    }
}