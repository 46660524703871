@charset "UTF-8";

.works {
    &-panel {
        display: flex;
        padding: 10px;
        background-color: $color-white;
        box-shadow: 0 0 10px $color-shadow;
        
        @include view-at(sp) {
            padding: 5px;
        }

        &-image {
            width: 50%;
            padding: 10px;

            @include view-at(sp) {
                padding: 5px;
            }
        }

        &-detail {
            width: 50%;
            padding: 10px;
        }

        &-date {
            font-size: rem(12px);
            line-height: 1;
            margin: 5px 0;
    
            @include view-at(sp) {
                font-size: rem(10px);
                margin: 0 0 5px;
            }
        }
    
        &-name {
            font-size: rem(22px);
            font-weight: $medium;
            line-height: 1.5;
            margin-bottom: 10px;
    
            @include view-at(sp) {
                font-size: rem(18px);
                margin-bottom: 5px;
            }
        }

        &-comment {
            color: $color-text-light;
            font-size: rem(14px);
            line-height: 1.6;

            @include view-at(sp) {
                font-size: rem(12px);
            }
        }
    }

    &-single {
        &-lead {
            font-size: rem(26px);
            font-weight: $medium;
            line-height: 1.6;
            letter-spacing: 0.15em;
            margin-bottom: 30px;

            @include view-at(sp) {
                font-size: rem(18px);
                margin-bottom: 20px;
            }
        }

        &-comment {
            padding: 20px 25px;
            background-color: $color-blue-snow;
        }
    }

    &-slider {
        &-item {
            max-height: 75%;
            
            img {
                margin: auto;
            }
        }

        &-comment {
            position: absolute;
            right: 0;
            bottom: 20px;
            font-size: rem(16px);
            line-height: 1.4;
            letter-spacing: 0.1em;
            text-align: right;
            padding: 10px 15px;
            background-color: rgba($color-blue-snow, .5);

            @include view-at(tab) {
                font-size: rem(14px);
            }
            @include view-at(sp) {
                position: static;
                font-size: rem(12px);
                text-align: left;
            }

            &:empty {
                padding: 0;
                background-color: transparent;
            }
        }
    }

    &-thumbnail {
        margin: -10px !important;

        @include view-at(sp) {
            margin: -5px !important;
        }

        &-item {
            position: relative;
            width: calc(100% / 6);
            max-height: 120px;
            text-align: center;
            padding: 10px !important;
            overflow: hidden;

            @include view-at(sp) {
                width: calc(100% / 3);
                max-height: 80px;
                padding: 5px !important;
            }

            &.thumbnail-current {
                .works-thumbnail-inner {
                    &::before {
                        background-color: transparent;
                    }
                }
            }

            &:hover {
                @include view-at(pc) {
                    .works-thumbnail-inner {
                        &::before {
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        &-inner {
            position: relative;
            width: 100%;
            height: 100%;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .6);
                transition: all .3s;
            }

            img {
                display: block;
                width: 100%;
                max-width: 100%;
                height: 100vh;
                max-height: 100%;
                margin: auto;
                object-fit: cover;
            }
        }
    }
}
