/* --------------------------
margin、padding指定
-------------------------- */

.u-ma {
  margin: auto;
}

@for $i from 0 through 20 {
  .u-mt#{$i * 5} {margin-top: #{$i * 5}px !important;}
  .u-mr#{$i * 5} {margin-right: #{$i * 5}px !important;}
  .u-ml#{$i * 5} {margin-left: #{$i * 5}px !important;}
  .u-mb#{$i * 5} {margin-bottom: #{$i * 5}px !important;}
  .u-pt#{$i * 5} {padding-top: #{$i * 5}px !important;}
  .u-pr#{$i * 5} {padding-right: #{$i * 5}px !important;}
  .u-pl#{$i * 5} {padding-left: #{$i * 5}px !important;}
  .u-pb#{$i * 5} {padding-bottom: #{$i * 5}px !important;}
}
@include view-at(pc){
  @for $i from 0 through 20 {
    .u-mt#{$i * 5}--pc {margin-top: #{$i * 5}px !important;}
    .u-mr#{$i * 5}--pc {margin-right: #{$i * 5}px !important;}
    .u-ml#{$i * 5}--pc {margin-left: #{$i * 5}px !important;}
    .u-mb#{$i * 5}--pc {margin-bottom: #{$i * 5}px !important;}
    .u-pt#{$i * 5}--pc {padding-top: #{$i * 5}px !important;}
    .u-pr#{$i * 5}--pc {padding-right: #{$i * 5}px !important;}
    .u-pl#{$i * 5}--pc {padding-left: #{$i * 5}px !important;}
    .u-pb#{$i * 5}--pc {padding-bottom: #{$i * 5}px !important;}
  }
}
@include view-at(mdpi){
  @for $i from 0 through 20 {
    .u-mt#{$i * 5}--mdpi {margin-top: #{$i * 5}px !important;}
    .u-mr#{$i * 5}--mdpi {margin-right: #{$i * 5}px !important;}
    .u-ml#{$i * 5}--mdpi {margin-left: #{$i * 5}px !important;}
    .u-mb#{$i * 5}--mdpi {margin-bottom: #{$i * 5}px !important;}
    .u-pt#{$i * 5}--mdpi {padding-top: #{$i * 5}px !important;}
    .u-pr#{$i * 5}--mdpi {padding-right: #{$i * 5}px !important;}
    .u-pl#{$i * 5}--mdpi {padding-left: #{$i * 5}px !important;}
    .u-pb#{$i * 5}--mdpi {padding-bottom: #{$i * 5}px !important;}
  }
}
@include view-at(laptop){
  @for $i from 0 through 20 {
    .u-mt#{$i * 5}--laptop {margin-top: #{$i * 5}px !important;}
    .u-mr#{$i * 5}--laptop {margin-right: #{$i * 5}px !important;}
    .u-ml#{$i * 5}--laptop {margin-left: #{$i * 5}px !important;}
    .u-mb#{$i * 5}--laptop {margin-bottom: #{$i * 5}px !important;}
    .u-pt#{$i * 5}--laptop {padding-top: #{$i * 5}px !important;}
    .u-pr#{$i * 5}--laptop {padding-right: #{$i * 5}px !important;}
    .u-pl#{$i * 5}--laptop {padding-left: #{$i * 5}px !important;}
    .u-pb#{$i * 5}--laptop {padding-bottom: #{$i * 5}px !important;}
  }
}
@include view-at(tab) {
  @for $i from 0 through 20 {
    .u-mt#{$i * 5}--tab {margin-top: #{$i * 5}px !important;}
    .u-mr#{$i * 5}--tab {margin-right: #{$i * 5}px !important;}
    .u-ml#{$i * 5}--tab {margin-left: #{$i * 5}px !important;}
    .u-mb#{$i * 5}--tab {margin-bottom: #{$i * 5}px !important;}
    .u-pt#{$i * 5}--tab {padding-top: #{$i * 5}px !important;}
    .u-pr#{$i * 5}--tab {padding-right: #{$i * 5}px !important;}
    .u-pl#{$i * 5}--tab {padding-left: #{$i * 5}px !important;}
    .u-pb#{$i * 5}--tab {padding-bottom: #{$i * 5}px !important;}
  }
}
@include view-at(sp) {
  @for $i from 0 through 20 {
    .u-mt#{$i * 5}--sp {margin-top: #{$i * 5}px !important;}
    .u-mr#{$i * 5}--sp {margin-right: #{$i * 5}px !important;}
    .u-ml#{$i * 5}--sp {margin-left: #{$i * 5}px !important;}
    .u-mb#{$i * 5}--sp {margin-bottom: #{$i * 5}px !important;}
    .u-pt#{$i * 5}--sp {padding-top: #{$i * 5}px !important;}
    .u-pr#{$i * 5}--sp {padding-right: #{$i * 5}px !important;}
    .u-pl#{$i * 5}--sp {padding-left: #{$i * 5}px !important;}
    .u-pb#{$i * 5}--sp {padding-bottom: #{$i * 5}px !important;}
  }
}