/* --------------------------
フッター
-------------------------- */

.p-footer {
    background-color: $color-gray;

    &-pagetop {
        position: fixed;
        right: 20px;
        bottom: 15px;
        z-index: 999;

        @include view-at(tab) {
            right: 10px;
            bottom: 10px;
        }

        a {
            position: relative;
            display: inline-block;
            width: 48px;
            height: 48px;
            background-color: $color-navy;
            border: 1px solid $color-navy;
            border-radius: 48px;

            @include view-at(sp) {
                width: 40px;
                height: 40px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 10px;
                height: 10px;
                margin-top: 3px;
                border: 0px;
                border-top: solid 3px $color-white;
                border-right: solid 3px $color-white;
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    &-inner {
        width: $contents-width;
        max-width: 100%;
        margin: 0 auto;
        padding: $contents-padding;

        @include view-at(mdpi) {
            width: 100%;
        }

        @include view-at(sp) {
            padding: 20px;
        }
    }

    &-link {
        &-wrapper {
            background-color: $color-blue-snow;
            border-top: 1px solid $color-gray;
        }

        &-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: $color-white;
            font-size: rem(18px);
            font-weight: $medium;
            text-align: center;
            padding: 25px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            @include view-at(sp) {
                font-size: rem(14px);
                padding: 15px 10px;
            }

            &:hover {
                opacity: 1;

                .button {
                    color: $color-navy;
                    background-color: $color-white;

                    .arrow {
                        &::before {
                            border-color: $color-navy;
                        }
                    }
                }
            }

            &.virtual {
                background-image: url(../images/common/link-virtual.jpg);
            }
            &.recruit {
                background-image: url(../images/common/link-recruit.jpg);
            }

            .icon {
                font-size: 150%;
                margin-bottom: 10px;

                @include view-at(sp) {
                    font-size: 125%;
                    margin-bottom: 5px;
                }
            }
            .text {
                margin-bottom: 20px;

                @include view-at(sp) {
                    margin-bottom: 10px;
                }
            }
            .button {
                background-color: transparent;
                border-color: $color-white;

                @include view-at(sp) {
                    min-width: 140px;
                    font-size: rem(12px);
                }
            }
        }
    }

    &-contact {
        @include view-at(pc) {
            display: flex;
            justify-content: flex-start;
        }

        &-text {
            color: $color-white;
            font-size: rem(14px);
            line-height: 1.7;

            @include view-at(sp) {
                font-size: rem(12px);
            }

            &.large {
                font-size: rem(26px);
                font-weight: $bold;
                line-height: 1.3;
                margin-right: 30px;
                margin-bottom: 0;

                @include view-at(sp) {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }

            >a {
                color: $color-white;
            }
        }
    }

    &-banner {
        background-color: $color-white;
    }

    &-copyright {
        color: $color-white;
        font-size: rem(11px);
        line-height: 1;
        text-align: center;
        padding: 14px 0 15px;
        background-color: $color-text;

        @include view-at(tab) {
            font-size: rem(10px);
        }
    }
}