/* --------------------------
ヘッダー
-------------------------- */

.p-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: $color-white-dark;
    z-index: 1000;
    transition: all .3s;

    @include view-at(tab) {
        height: 56px;
    }

    &.is-animation {
        @include view-at(overtab) {
            height: 60px;
        }
    }

    &-inner {
        width: $contents-width;
        max-width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: $contents-padding;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include view-at(mdpi) {
            width: 100%;
        }

        @include view-at(tab) {
            padding: 0 15px;
        }
    }

    &-logo {
        position: relative;
        text-align: left;
        z-index: 9999;

        a {
            display: block;
            color: $color-black;
            font-size: rem(26px);
            font-weight: $bold;
            letter-spacing: 0.15em;
        }
    }

    &-menu {
        display: none;

        @include view-at(tab) {
            position: fixed;
            top: 13px;
            right: 13px;
            display: inline-block;
            z-index: 1001;
        }
    }
}