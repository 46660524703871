@charset "UTF-8";

.top {
    &-mv {
        position: relative;
        max-width: 1920px;
        text-align: center;
        margin: auto;

        &-inner {
            @include view-at(pc) {
                .u-hide-pc {
                    display: none !important;
                }
            }
            @include view-at(sp) {
                .u-hide-sp {
                    display: none !important;
                }
            }
        }

        // slick 上書き
        > .slick-dots {
            bottom: 16px;

            @include view-at(sp) {
                bottom: 5px;
            }

            li {
                width: 16px;
                height: 16px;
                margin: 0 8px;

                button {
                    position: relative;
                    width: 16px;
                    height: 16px;
                    padding: 0;
                    border: 1px solid transparent;
                    border-radius: 8px;
                    transition: all .3s;

                    &::before {
                        content: '';
                        top: 2px;
                        left: 2px;
                        width: 10px;
                        height: 10px;
                        background-color: $color-white;
                        border-radius: 5px;
                        opacity: 1;
                    }
                }

                &.slick-active {
                    button {
                        border-color: $color-white;
                    }
                }
            }
        }
    }

    &-topics {
        &-wrapper {
            @include view-at(pc) {
                display: flex;
                flex-wrap: nowrap;
                justify-content: center;
            }
        }

        &-title {
            @include view-at(pc) {
                flex-shrink: 0;
                padding-right: 25px;
                border-right: 2px solid $color-text;
            }
        }
        &-h2 {
            margin-top: -10px;
            margin-bottom: 0;

            @include view-at(pc) {
                padding-bottom: 10px;
                border-bottom: none;
            }
        }

        &-news {
            display: flex;
            align-items: center;
            padding: 20px 0 20px 30px;

            @include view-at(sp) {
                padding: 15px 0;
            }

            table {
                tr {
                    &:not(:first-child) {
                        td {
                            padding-top: 20px;

                            @include view-at(sp) {
                                padding-top: 15px;
                            }
                        }
                    }
                }
                
                td {
                    line-height: 1.4;
                    vertical-align: baseline;

                    @include view-at(sp) {
                        font-size: rem(12px);
                    }

                    &:first-child {
                        padding-right: 1em;
                    }
                }
            }
        }
    }

    &-image {
        &-wrapper {
            position: relative;
        }

        &-item {
            position: absolute;
            
            &--left {
                left: 2%;
                bottom: -100px;
                width: 23%;

                @include view-at(sp) {
                    left: 3%;
                    bottom: -70px;
                    width: 50%;
                }
            }
            &--right {
                top: -100px;
                right: 2%;
                width: 22%;

                @include view-at(pc) {
                    text-align: right;

                    img.pc {
                        width: 85%;
                    }
                }
                @include view-at(sp) {
                    top: -80px;
                    right: 5%;
                    width: 55%;
                }
            }

            img {
                filter: drop-shadow(10px 10px 10px rgba($color: $color-navy, $alpha: .3));
            }
        }
    }

    &-twitter {
        max-width: 100%;
        width: 340px;
        margin: auto;

        @include view-at(tab) {
            width: 100%;
        }
        
        .twitter-timeline {
            @include view-at(tab) {
                margin: auto;
            }
        }

        // &-wrapper {
        //     position: absolute;
        //     top: 115px;
        //     left: 20px;
        // }

        &-title {
            display: flex;
            justify-content: center;

            .follow {
                min-width: auto;
                color: $color-navy;
                font-size: rem(16px);
                font-weight: $bold;
                letter-spacing: 0.05em;
                padding: 0.5em 1em 0.75em;
                background-color: $color-white;

                @include view-at(sp) {
                    font-size: rem(14px);
                }

                &:hover {
                    color: $color-white;
                    background-color: $color-navy;
                }

                img {
                    max-height: 16px;
                    margin-right: 11px;
                    filter: none;

                    @include view-at(sp) {
                        max-height: 14px;
                    }
                }

                span {
                    font-size: 75%;
                }
            }

            // .logo {
            //     display: flex;
            //     align-items: center;
            //     color: $color-text;

            //     &.twitter {
            //         img {
            //             max-height: 16px;
            //             margin-right: 11px;
            //             filter: none;

            //             @include view-at(sp) {
            //                 max-height: 14px;
            //             }
            //         }
    
            //         span {
            //             font-size: rem(16px);
            //             font-weight: $bold;

            //             @include view-at(sp) {
            //                 font-size: rem(14px);
            //             }
            //         }
            //     }
            // }

            // .button {
            //     min-width: auto;
            //     font-size: rem(14px);
            //     padding: 0.5em 1em;

            //     @include view-at(sp) {
            //         font-size: rem(12px);
            //     }
            // }
        }
    }

    &-panel {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -15px;

        &-item {
            position: relative;
            width: calc(100% / 3);
            padding: 15px;

            @include view-at(sp) {
                width: 100%;
            }

            &:nth-child(2) {
                @include view-at(pc) {
                    margin-top: 50px;
                }
            }
            &:nth-child(3) {
                @include view-at(pc) {
                    margin-top: 100px;
                }
            }
        }

        &-inner {
            padding-bottom: 20px;
            background-color: $color-white;
            box-shadow: 0 0 10px $color-shadow;
        }
        
        &-name {
            display: flex;
            flex-direction: column;
            font-size: rem(22px);
            font-weight: $medium;
            line-height: 1.4;
            text-align: center;
            margin-top: 20px;
            
            @include view-at(sp) {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: rem(18px);
            }

            span {
                &.icon {
                    font-size: rem(30px);

                    @include view-at(sp) {
                        font-size: rem(24px);
                        margin-right: 5px;
                        padding-top: 3px;
                    }
                }
            }
        }

        &-button {
            min-width: 255px;
            margin: auto;
        }
    }

    &-works {
        &-wrapper {
            padding: 0 60px;

            @include view-at(sp) {
                padding: 0;
            }
        }

        &-inner {
            display: block;
            height: calc(100% - 30px);
            margin: 15px;
            padding: 20px;
            background-color: $color-white;
            box-shadow: 0 0 10px $color-shadow;

            @include view-at(sp) {
                height: calc(100% - 20px);
                margin: 10px;
                padding: 15px;
            }
        }

        &-date {
            font-size: rem(12px);
            line-height: 1;
            margin: 15px 0 5px;

            @include view-at(sp) {
                font-size: rem(10px);
                margin-top: 10px 0 5px;
            }
        }

        &-name {
            font-size: rem(22px);
            font-weight: $medium;
            line-height: 1.5;
            margin-bottom: 10px;

            @include view-at(sp) {
                font-size: rem(18px);
                margin-bottom: 5px;
            }
        }

        &-comment {
            color: $color-text-light;
            font-size: rem(14px);
            line-height: 1.6;

            @include view-at(sp) {
                font-size: rem(12px);
            }
        }

        // slick 上書き
        > .slick-arrow {
            display: inline-block;
            width: 40px;
            height: 40px;
            background-color: $color-navy;
            border: 1px solid $color-navy;
            border-radius: 20px;
            transition: all .3s;
            z-index: 1;

            @include view-at(sp) {
                width: 34px;
                height: 34px;
            }

            &:hover {
                opacity: 0.7;
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 10px;
                height: 10px;
                border: 0px;
                border-top: solid 2px $color-white;
                border-right: solid 2px $color-white;
                opacity: 1;

                @include view-at(sp) {
                    width: 8px;
                    height: 8px;
                }
            }
        }

        > .slick-prev {
            left: -60px;

            @include view-at(sp) {
                left: 10px;
            }

            &::before {
                transform: translate(-50%, -50%) rotate(-135deg);
            }
        }

        > .slick-next {
            right: -60px;

            @include view-at(sp) {
                right: 10px;
            }

            &::before {
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }

        .slick-track {
            display: flex;

            .slick-slide {
                height: auto !important;
            }
        }
    }
}