.edit-post-visual-editor__post-title-wrapper,
.block-editor-block-list__layout {
    max-width: 940px;
    margin: 0 auto;
}

.edit-post-visual-editor__post-title-wrapper {
    margin: 2em auto 0;

    h1 {
        &.editor-post-title.editor-post-title__input {
            font-size: 2.44em;
            font-weight: 700;
            line-height: 1.4;
            padding: 19px 0;
        }
    }
}

.block-editor-block-list__layout {
    h2 {
        @extend .c-h2;
    }

    h3 {
        @extend .c-h3;
    }

    h4 {
        @extend .c-h4;
    }

    h5 {
        @extend .c-h5;
    }

    h6 {
        @extend .c-h6;
    }

    ul {
        @extend .c-list;
        @extend .c-list--point;
    }

    ol {
        @extend .c-list;
        @extend .c-list--order;
    }

    a {
        word-break: break-all;
    }

    blockquote {
        background-color: $color-gray-light;
        padding: 15px;
        margin-bottom: 1em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .wp-block-button__link {
        @extend .c-button;
    }
}

.wp-block {
    max-width: 100%;
}

.wp-block-image,
.wp-block-columns,
.wp-block-media-text,
.wp-block-cover,
.wp-block-quote,
.wp-block-separator,
.wp-block-button {
    margin: 30px 0;
}

.wp-block-column {

    .wp-block-image,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 0;
    }
}

.wp-block-quote {
    padding: 20px;
    background-color: $color-gray-snow;
}

.wp-block-image {
    &::after {
        content: "";
        clear: both;
        font-size: 0;
        height: 0;
        display: block;
        visibility: hidden;
    }
}

.wp-block-table {
    &:not(:last-of-type) {
        margin-bottom: 60px;

        @include view-at(sp) {
            margin-bottom: 40px;
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;
        font-size: rem(16px);
        line-height: 1.5;
        letter-spacing: 0.1em;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        word-break: break-all;

        @include view-at(sp) {
            font-size: rem(14px);
        }

        thead {
            tr {
                th {
                    padding: 15px;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid $color-border;
            }

            td {
                width: 70%;
                padding: 10px 20px;
                vertical-align: top;
                border: none !important;

                @include view-at(sp) {
                    width: 100%;
                    display: block;
                }

                .small {
                    font-size: 80%;
                }

                &:first-child {
                    width: 30%;
                    padding: 10px 20px;
                    word-break: keep-all;
                    font-weight: $regular;
                    vertical-align: top;
                    background-color: $color-gray-snow;

                    @include view-at(sp) {
                        width: 100%;
                        display: block;
                        border-bottom: 1px solid $color-border !important;
                    }

                    .small {
                        font-size: 80%;
                    }
                }
            }
        }
    }

    &.aligncenter,
    &.alignleft,
    &.alignright {
        width: 100%;
    }
}

@include view-at(sp) {
    .wp-block-columns {
        display: block;
        margin: 0;
    }

    .wp-block-column+.wp-block-column {
        margin-top: 30px;
    }
}

.wp-block-file:not(.wp-element-button) a {
    font-size: 1rem;
}

.wp-block-file *+.wp-block-file__button {
    background-color: #000;
    color: #fff;
    text-decoration: none;
}

.wp-block-file *+a.wp-block-file__button {
    font-size: .8rem;
}

.wp-block-file .wp-block-file__button-richtext-wrapper {
    background-color: #000;
    color: #fff;
    font-size: .8rem;
    border-radius: 2em;
}

ul.block-editor-block-list__layout>li {
    display: flex;
}